.carousel-indicators .active {
  width: 12px !important;
  height: 12px !important;
  margin: 1px !important;
  background-color: #fff !important;
}
.carousel-indicators li {
  width: 12px !important;
  height: 12px !important;
  background-color: #f00 !important;
  border: 1px solid #f00 !important;
}
.carousel-indicators {
  bottom: -18px !important;
}
.modal-lg {
  width: 56vw;
  max-width: 56vw;
  max-height: 90vh;
  overflow: hidden;
}
@media (max-width: 768px) {
  .modal-lg {
    max-height: 80vh;
    max-width: 80vw;
    width: 80vw;
  }
}
@media (max-width: 480px) {
  .modal-lg {
    max-height: 80vh;
    max-width: 80vw;
    width: 80vw;
  }
}
#banner_img {
  max-height: 80vh;
  width: auto;
}
.modal {
  left: auto;
  right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
/* .modal-content.modal-lg::-webkit-scrollbar {
  display: none;
} */

.sliderr2 .slick-prev, .slick-next {
  opacity: .5;
}

.sliderr2 .slick-next {
  right: 11%;
}

.slick-prev:before, .slick-next:before{
  opacity: .60;
}

@media (max-width: 767px) {
  .sliderr2 .slick-next {
    right: 10%;
  }
}
